import Vue from 'vue'
import VueRouter from 'vue-router'

const AppLayout = () => import('@/layouts/App');
const Home = () => import('@/views/Home');
const About = () => import('@/views/About');
const Service = () => import('@/views/Service');
const BecomeDriver = () => import('@/views/BecomeDriver');
const Contact = () => import('@/views/Contact');
const Policy = () => import('@/views/Policy');




Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  routes: [
      {
          path: '',
          component: AppLayout,
          children: [
              { path: '/', component: Home, name:'Home' },
              { path: '/about', component: About, name:'About' },
              { path: '/services', component: Service, name:'Service' },
              { path: '/become-driver', component: BecomeDriver, name:'BecomeDriver' },
              { path: '/contact', component: Contact, name:'Contact' },
              { path: '/policy', component: Policy, name:'Policy' },
          ],
      },
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

export default router
